export default [
  {
    path: "/lottery",
    name: "lottery",
    component: () => import("@/views/lottery/pools/Pools.vue"),
    redirect: { name: `lottery-product` },
    meta: {
      // requiresAuth: true,
      pageTitle: "Pools List"
    },
    children: [
      {
        path: "/lottery/product",
        name: "lottery-product",
        component: () => import("@/views/lottery/pools/PoolsList.vue"),
        meta: {
          pageTitle: "Pools Detail"
        }
      },
      {
        path: "/lottery/product/detail/:poolsCode?",
        props: true,
        name: "lottery-product-detail",
        component: () => import("@/views/lottery/pools/PoolsDetail.vue"),
        meta: {
          pageTitle: "Pools Detail"
        }
      }
    ]
  },
  {
    path: "/lottery/round",
    name: "lottery-round",
    component: () => import("@/views/lottery/round/RoundList.vue"),
    meta: {
      pageTitle: "Round List"
    }
  }
];
