export default [
  {
    path: "/transaction/deposit",
    name: "deposit",
    component: () => import("@/views/transaction/deposit/Deposit.vue"),
    redirect: { name: "deposit-list" },
    children: [
      {
        path: "/transaction/deposit/list",
        name: "deposit-list",
        component: () => import("@/views/transaction/deposit/DepositList.vue"),
        meta: {
          pageTitle: "Deposit List",
          breadcrumb: [
            { text: `Transaction`, active: true },
            {
              text: "Deposit",
              active: true
            }
          ]
        }
      }
      // {
      //   path: "/transaction/deposit/detail",
      //   name: "deposit-detail",
      //   component: () => import("@/views/transaction/deposit/DepositDetail.vue"),
      //   meta: {
      //     pageTitle: "Deposit Record",
      //     breadcrumb: [
      //       { text: `Transaction`, active: true },
      //       {
      //         text: "Deposit",
      //         to: { name: "deposit-list" }
      //       },
      //       {
      //         text: "Detail",
      //         active: true
      //       }
      //     ]
      //   }
      // }
    ]
  },
  {
    path: "/transaction/withdrawal",
    name: "withdrawal",
    component: () => import("@/views/transaction/withdrawal/Withdrawal.vue"),
    redirect: { name: "withdrawal-list" },
    children: [
      {
        path: "/transaction/withdrawal/list",
        name: "withdrawal-list",
        component: () => import("@/views/transaction/withdrawal/WithdrawalList.vue"),
        meta: {
          pageTitle: "Withdrawal List",
          breadcrumb: [
            { text: `Transaction`, active: true },
            {
              text: "Withdrawal",
              active: true
            }
          ]
        }
      },
      
      // {
      //   path: "/transaction/withdrawal/detail",
      //   name: "withdrawal-detail",
      //   component: () => import("@/views/transaction/withdrawal/WithdrawalDetail.vue"),
      //   meta: {
      //     pageTitle: "Withdrawal Record",
      //     breadcrumb: [
      //       { text: `Transaction`, active: true },
      //       {
      //         text: "Withdrawal",
      //         to: { name: "withdrawal-list" }
      //       },
      //       {
      //         text: "Detail",
      //         active: true
      //       }
      //     ]
      //   }
      // }
    ]
  },
  {
    path: "/transaction/withdrawalpending",
    name: "withdrawalPending",
    component: () => import("@/views/transaction/withdrawalPending/WithdrawalPending.vue"),
    redirect: { name: "withdrawalpending-list" },
    children: [
      {
        path: "/transaction/withdrawalpending/list",
        name: "withdrawalpending-list",
        component: () => import("@/views/transaction/withdrawalPending/WithdrawalPendingList.vue"),
        meta: {
          pageTitle: "Withdrawal Pending List",
          breadcrumb: [
            { text: `Transaction`, active: true },
            {
              text: "Withdrawal Pending",
              active: true
            }
          ]
        }
      },
    ]
  },
  {
    path: "/transaction/paymentaccount",
    name: "payment-account",
    component: () => import("@/views/transaction/paymentAccount/PaymentAccount.vue"),
    redirect: { name: "payment-account-list" },
    children: [
      {
        path: `/transaction/paymentaccount/list`,
        name: `payment-account-list`,
        component: () => import("@/views/transaction/paymentAccount/PaymentAccountList.vue"),
        meta: {
          pageTitle: "Payment Account List",
          breadcrumb: [
            { text: `Administration`, active: true },
            {
              text: "Payment Account",
              active: true
            }
          ]
        }
      },
      {
        path: `/transaction/paymentaccount/mutation/:accountNo`,
        name: `payment-account-mutation`,
        component: () => import("@/views/transaction/paymentAccount/PaymentAccountMutation.vue"),
        meta: {
          pageTitle: "Payment Account Mutation",
          breadcrumb: [
            { text: `Administration`, active: true },
            { text: "Payment Account", to: { name: `payment-account` } },
            { text: "Mutation", active: true }
          ]
        },
        props: true
      },
      {
        path: `/transaction/paymentaccount/adjustmentmutation/:accountNo`,
        name: `payment-account-adjustment-mutation`,
        component: () => import("@/views/transaction/paymentAccount/PaymentAccountAdjustmentMutation.vue"),
        meta: {
          pageTitle: "Payment Account Adjustment Mutation",
          breadcrumb: [
            { text: `Administration`, active: true },
            { text: "Payment Account", to: { name: `payment-account` } },
            { text: "Adjustment Mutation", active: true }
          ]
        },
        props: true
      }
    ]
  }
];
