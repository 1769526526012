export default [
  {
    path: "/oauth/login",
    name: "oauth-login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      isPublic: true
    }
  },
  {
    path: "/oauth/callback",
    name: "oauth-callback",
    component: () => import("@/views/auth/Callback.vue"),
    meta: {
      layout: "full",
      isPublic: true
    }
  },
  {
    path: "/oauth/silent",
    name: "oauth-silent",
    component: () => import("@/views/auth/Silent.vue"),
    meta: {
      layout: "full",
      isPublic: true
    }
  },
  {
    path: "/oauth/after",
    name: "oauth-after",
    component: () => import("@/views/auth/AfterLogin.vue"),
    meta: {
      layout: "full",
      isPublic: true
    }
  }
];
