export default [
  {
    path: "/audit/mutation",
    name: "audit-mutation",
    component: () => import("@/views/audit/mutation/AuditMutation.vue"),
    meta: {
      pageTitle: "Mutation Audit",
      breadcrumb: [
        {
          text: "Mutation Audit",
          active: true
        }
      ]
    }
  }
];
