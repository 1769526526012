import Vue from "vue";

//Format Full Date
Vue.filter("fullDate", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("dddd, DD-MMM-YYYY HH:mm:ss");
});

//Format Date Time
Vue.filter("dateTime", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD-MMM-YYYY HH:mm:ss");
});

//Format Date Only
Vue.filter("date", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD / MMM / YYYY");
});

//Format Short Date
Vue.filter("shortdate", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("DD-MMM-YY");
});

//Format Month Only
Vue.filter("month", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("MMM YY");
});

//Format Time Only
Vue.filter("time", function (value) {
  if (!value) return "";
  return Vue.moment(value).format("HH:mm:ss");
});

//Convert LocalDate
Vue.filter("localDate", function (value) {
  if (!value) return "";
  let tzOffsetMin = new Date().getTimezoneOffset();
  return Vue.moment(value).add(-tzOffsetMin, "minutes").format("DD/MMM/YYYY HH:mm:ss");
});
