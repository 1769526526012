import axios from "axios";
import store from "@/store";

export default {
  errorHandler(error) {
    if (!error.response) {
      return Promise.reject("Network Error.");
    }

    if (error.response.status == 403) return Promise.reject(`You are not authorized to perform this action.`);
    
    if (error.response.status !== 401) {
      return Promise.reject(error.response.data.message);
    }

    if (error.response.status == 401) {
      store.dispatch('oidcStore/signOutOidc').then(() => {
        localStorage.clear();
        sessionStorage.clear();
      });    
    }      

    if (error.config.url === "auth/refreshtoken") {
      this.clearAuth();
      return Promise.reject("Not Authorized. Please sign in.");
    }

    return;
  },

  successHandler(response) {
    if (response.config.responseType === `blob`) {
      return Promise.resolve(response);
    } else if (response.data.status === "Success") {
      return Promise.resolve(response.data.result);
    } else {
      return Promise.reject(response.data.message);
    }
  },

  init() {
    axios.defaults.baseURL = store.state.config.adminApiUrl;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.response.use(
      response => this.successHandler(response),
      error => this.errorHandler(error)
    );
  },

  setHeader(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  removeHeader() {
    delete axios.defaults.headers.common["Authorization"];
  },

  get(resource, query) {
    if (query) resource += this.buildQuery(query);
    return axios.get(resource);
  },

  post(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.post(resource, params);
  },

  put(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.put(resource, params);
  },

  delete(resource, query) {
    if (query) resource += this.buildQuery(query);
    return axios.delete(resource);
  },

  patch(resource, params, query) {
    if (query) resource += this.buildQuery(query);
    return axios.patch(resource, params);
  },

  download(resource) {
    return axios.get(resource, { responseType: "blob" });
  },

  downloadPost(resource, payload) {
    return axios.post(resource, payload, { responseType: "blob" });
  },

  upload(resource, formData) {
    var config = { headers: { "Content-Type": "multipart/form-data" } };
    return axios.post(resource, formData, config);
  },

  buildQuery(qObject) {
    let retVal = "";
    let keys = Object.keys(qObject);
    keys.forEach((x, i) => {
      if (i === 0) retVal += `?${x}=${qObject[x]}`;
      else retVal += `&${x}=${qObject[x]}`;
    });
    return retVal;
  }
};
