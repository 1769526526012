const authUrl = "https://midas-auth.nextvibes.com";
const baseUrl = "https://midas-ui.nextvibes.com";

export default {
  accessTokenExpiringNotificationTime: 3570,
  authority: authUrl,
  clientId: "interactive",
  clientSecret: "ClientSecret1",
  responseType: "code",
  scope: "openid profile midasagentapi1 midaslotteryapi",
  automaticSilentRenew: false,
  automaticSilentSignin: false,
  redirectUri: `${baseUrl}/oauth/callback`,
  postLogoutRedirectUri: baseUrl,
  popupRedirectUri: `${baseUrl}/oauth/callback`,
  silentRedirectUri: `${baseUrl}/oauth/silent`
};
