import Vue from "vue";
import wysiwyg from "vue-wysiwyg";

var options = {
  //   image: {
  //     uploadURL: "/api/myEndpoint",
  //     dropzoneOptions: {}
  //   },
  maxHeight: "500px"
};

Vue.use(wysiwyg, options); // config is optional. more below
import "vue-wysiwyg/dist/vueWysiwyg.css";
