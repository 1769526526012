<template>
  <div v-if="oidcIsAuthenticated || $route.meta.isPublic" id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
  <div v-else>
    <session-timeout></session-timeout>
  </div>
</template>

<!-- eslint-disable vue/order-in-components -->
<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";
import SessionTimeout from "@/views/auth/SessionTimeout.vue";
import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () => import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    SessionTimeout
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Midas",
    // all titles will be injected into this template
    titleTemplate: "%s | Midas App"
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcAuthenticationIsChecked"]),
    hasAccess: function () {
      return this.oidcIsAuthenticated || this.$route.meta.isPublic;
    },
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ["primary", "secondary", "success", "info", "warning", "danger", "light", "dark"];
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  mounted() {
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.addEventListener("vuexoidc:oidcError", this.oidcError);
    window.addEventListener("vuexoidc:automaticSilentRenewError", this.automaticSilentRenewError);
    window.addEventListener("vuexoidc:accessTokenExpired", this.accessTokenExpired);
  },
  destroyed() {
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.removeEventListener("vuexoidc:oidcError", this.oidcError);
    window.removeEventListener("vuexoidc:automaticSilentRenewError", this.automaticSilentRenewError);
    window.removeEventListener("vuexoidc:accessTokenExpired", this.accessTokenExpired);
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade"
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, val => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },
  methods: {
    ...mapActions("oidcStore", ["authenticateOidcPopup", "getOidcUser", "signOutOidc"]),
    userLoaded() {},
    oidcError() {},
    automaticSilentRenewError() {},
    accessTokenExpired() {
      this.signOutOidc().then(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
    },
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  }
};
</script>
