<template>
  <b-button :disabled="disabled" variant="success" @click.stop="onClick">
    <b-spinner v-if="disabled" variant="primary" small />
    <feather-icon v-else icon="SaveIcon"></feather-icon>
    Save
  </b-button>
</template>

<script>
export default {
  name: `SaveButton`,
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit(`onSave`);
    }
  }
};
</script>
