export default [
  {
    path: "/releaseNotes",
    name: "release-notes",
    component: () => import("@/views/dashboard/releaseNotes/ReleaseNotes.vue"),
    meta: {
      pageTitle: "Release Notes"
    }
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard"
    }
  }
];
