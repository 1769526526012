export default [
  {
    path: "/crm/userquery",
    name: "user-query",
    component: () => import("@/views/crm/UserQuery.vue"),
    meta: {
      pageTitle: "User Query",
      breadcrumb: [
        {
          text: "User Query",
          active: true
        }
      ]
    }
  }
];
