import Vue from "vue";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import Api from "@api";
import Auth from "@auth";

import { UPDATE_AUTH_URL, UPDATE_ADMIN_API_URL, UPDATE_LOTTERY_API_URL } from "@mutations";

// Global Components
import "./global-components";

import "@/libs";
import "@/mixins/general";
import "@/mixins/dialog";
import "@filters";
import "./registerServiceWorker";

require("@core/scss/core.scss");
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

const configUrl =
  process.env.NODE_ENV === `development` ? `${window.location.origin}/config.dev.json` : `${window.location.origin}/config.prod.json`;

Vue.axios.get(configUrl).then(async resp => {
  store.commit(UPDATE_AUTH_URL, resp.data.authUrl);
  store.commit(UPDATE_ADMIN_API_URL, resp.data.adminApiUrl);
  store.commit(UPDATE_LOTTERY_API_URL, resp.data.lotteryApiUrl);

  Api.init();
  Auth.init();

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
