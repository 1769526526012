import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Auth from "@auth";

const generateToast = (messageType, message) => {
  let icon, title, variant;
  switch (messageType.toLowerCase()) {
    case "success":
      title = "Success";
      icon = "CheckIcon";
      variant = "success";
      break;
    case "info":
      title = "Information";
      icon = "InfoIcon";
      variant = "info";
      break;
    case "error":
      title = "We got problem ...";
      icon = "FrownIcon";
      variant = "danger";
      break;
    default:
      break;
  }

  Vue.$toast({
    component: ToastificationContent,
    props: {
      title: title,
      icon,
      text: message,
      variant
    }
  });
};

Vue.mixin({
  components: { ToastificationContent },
  methods: {
    showToast(messageType, message) {
      generateToast(messageType, message);
    },
    async showConfirm(confirmMessage, confirmInput) {
      return new Promise(resolve => {
        if (confirmInput) {
          this.$swal({
            title: confirmMessage,
            input: "text",
            inputPlaceholder: `Enter "${confirmInput}" to confirm`,
            icon: "question",
            showCancelButton: true,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-lg btn-primary",
              cancelButton: "btn btn-lg btn-secondary ml-1"
            },

            inputValidator: value => {
              if (value !== confirmInput) {
                this.showToast("error", "Confirmation Text Not Matched.");
                resolve(false);
              }
            },
            buttonsStyling: false
          }).then(result => {
            resolve(result.isConfirmed);
          });
        } else {
          this.$swal({
            title: confirmMessage,
            text: "Please Confirm Your Action.",
            icon: "question",
            showCancelButton: true,
            focusConfirm: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-lg btn-primary",
              cancelButton: "btn btn-lg btn-secondary ml-1"
            },
            buttonsStyling: false
          }).then(result => {
            resolve(result.isConfirmed);
          });
        }
      });
    },
    toggleSuccess(active) {
      this.showToast(`success`, `Record ${active ? "Activated" : "Deactivated"}`);
    },
    toggleApprove(active) {
      this.showToast(`success`, `Withdrawal Has Been ${active ? "Approved" : "Rejected"}`);
    },
    async confirmPassword(title, label) {
      let result = await this.$swal({
        title: title,
        input: "password",
        inputLabel: label,
        inputPlaceholder: "Please type here...",
        icon: "question",
        showCancelButton: true,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-lg btn-primary",
          cancelButton: "btn btn-lg btn-secondary ml-1"
        },
        inputValidator: value => {
          if (!value) {
            this.showToast("error", "Remarks cannot be empty");
          }
        }
      });
      if (result.isConfirmed) {
        try {
          let response = await Auth.post(`account/checkpassword`, {userName: this.oidcUser.name ,oldPassword: result.value });
          if (response) return { confirmedPassword: response};
        } catch (error) {
          this.showToast(`error`, error);
          return { confirmedPassword: false};
        } 
      }else{
        return { confirmedPassword: false};
      }
    },

    async confirmRemarks(title, label) {
      let result = await this.$swal({
        title: title,
        input: "textarea",
        inputLabel: label,
        inputPlaceholder: "Please type here...",
        icon: "question",
        showCancelButton: true,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-lg btn-primary",
          cancelButton: "btn btn-lg btn-secondary ml-1"
        },
        inputValidator: value => {
          if (!value) {
            this.showToast("error", "Remarks cannot be empty");
          }
        }
      });

      return { confirmed: result.isConfirmed, remarks: result.value };
    }
  }
});

export { generateToast };
