import Api from "@api";

export default {
  async getAgentList({ commit }) {
    const response = await Api.get(`agent`);
    commit("SET_AGENT_LIST", response);
  },
  async getPaymentAccountList({ commit }) {
    const response = await Api.post(`paymentaccount/list`, { activeOnly: true });
    commit("SET_PAYMENT_ACCOUNT_LIST", response);
  },
  async getTrxTypeList({ commit }) {
    let response = await Api.get(`trxtype`, { showDefault: true, showInactive: false });

    const codesToRemove = [`DP`, `WD`, `ADJUSTMENT`, `WDFEE`];

    response = response.filter(item => !codesToRemove.includes(item.code));

    commit("SET_TRX_TYPE_LIST", response);
  }
};
