import OidcDev from "@/config/oidc.development.js";
import OidcProd from "@/config/oidc.production.js";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import Api from "@api";
import Auth from "@auth";
import store from "@/store";

let oidcSettings = process.env.NODE_ENV === "production" ? OidcProd : OidcDev;

export default vuexOidcCreateStoreModule(
  oidcSettings,
  {
    namespaced: true,
    dispatchEventsOnWindow: true
  },
  {
    userLoaded: user => {
      Api.setHeader(user.access_token);
      Auth.setHeader(user.access_token);
      store.dispatch("parameter/getAgentList");
      store.dispatch("parameter/getPaymentAccountList");
      store.dispatch("parameter/getTrxTypeList");
    },
    userUnloaded: () => {
      Api.removeHeader();
      Auth.removeHeader();
    },
    accessTokenExpiring: () => console.log("Access token will expire"),
    accessTokenExpired: () => {
      console.log("Access token did expire");
    },
    silentRenewError: () => console.log("OIDC user is unloaded"),
    userSignedOut: () => console.log("OIDC user is signed out"),
    oidcError: payload => console.log("OIDC error", payload),
    automaticSilentRenewError: payload => console.log("OIDC automaticSilentRenewError", payload)
  }
);
