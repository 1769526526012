<template>
  <b-button :disabled="disabled" variant="info" @click.stop="onClick">
    <b-spinner v-if="disabled" variant="primary" small />
    <feather-icon v-else icon="XCircleIcon"></feather-icon>
    Cancel
  </b-button>
</template>

<script>
export default {
  name: `CancelButton`,
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit(`onCancel`);
    }
  }
};
</script>
