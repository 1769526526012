export default [
  {
    path: "/promotion/promotion",
    name: "promotion",
    component: () => import("@/views/promotion/PromotionIndex.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "Promotion",
      breadcrumb: [
        {
          text: "Promotion",
          active: true
        }
      ]
    }
  },
  {
    path: "/promotion/promotion/create",
    name: "Create Promotion",
    component: () => import("@/views/promotion/PromotionFormData.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "Promotion",
      breadcrumb: [
        {
          text: "Promotion",
          active: true
        }
      ]
    }
  },
  {
    path: "/promotion/promotion/edit/:id",
    name: "Edit Promotion",
    component: () => import("@/views/promotion/PromotionFormData.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "Promotion",
      breadcrumb: [
        {
          text: "Promotion",
          active: true
        }
      ]
    }
  }
];
