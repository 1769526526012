export default [
  // {
  //   path: "/member",
  //   name: "member",
  //   component: () => import("@/views/member/MemberList.vue"),
  //   meta: {
  //     pageTitle: "Member List"
  //   }
  // },
  // {
  //   path: "/member/create",
  //   name: "create-member",
  //   component: () => import("@/views/member/MemberForm.vue"),
  //   meta: {
  //     pageTitle: "Member",
  //     breadcrumb: [
  //       {
  //         text: "Create",
  //         active: true
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: "/member/edit/:agentCode/:username",
  //   name: "edit-member",
  //   component: () => import("@/views/member/MemberForm.vue"),
  //   props: true,
  //   meta: {
  //     pageTitle: "Edit",
  //     breadcrumb: [
  //       {
  //         text: "Edit",
  //         active: true
  //       }
  //     ]
  //   }
  // }
];
