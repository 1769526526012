export default [
  {
    path: "/me",
    name: "profile",
    component: () => import("@/views/me/Me.vue"),
    meta: {
      pageTitle: "My Profile"
    }
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full"
    }
  },
  {
    path: "*",
    redirect: { name: `dashboard` }
  }
];
