export default [
  {
    path: "/administration/useraccount",
    name: "user-account",
    component: () => import("@/views/administration/userAccount/UserAccountList.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "User Account List",
      breadcrumb: [
        {
          text: "User Account",
          active: true
        }
      ]
    }
  },
  {
    path: "/administration/agent",
    name: "agent",
    component: () => import("@/views/administration/agent/AgentList.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "Agent List",
      breadcrumb: [
        {
          text: "Administration",
          active: true
        },
        {
          text: "Agent",
          active: true
        }
      ]
    }
  },
  {
    path: "/administration/trxtype",
    name: "trxtype",
    component: () => import("@/views/administration/trxType/TrxTypeList.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "Transaction Type List",
      breadcrumb: [
        {
          text: "Transaction Type",
          active: true
        }
      ]
    }
  },
  {
    path: "/administration/setting",
    name: "setting",
    component: () => import("@/views/administration/settings/SettingList.vue"),
    meta: {
      // requiresAuth: true,
      pageTitle: "Settings List",
      breadcrumb: [
        {
          text: "Settings",
          active: true
        }
      ]
    }
  }
];
