export default [
  {
    path: "/report",
    name: "report",
    redirect: { name: `account-balance-report` },
    component: () => import("@/views/report/Report.vue"),
    children: [
      {
        path: "/report/account-balance",
        name: "account-balance-report",
        component: () => import("@/views/report/paymentaccount/PaymentAccountBalanceReport.vue"),
        meta: {
          pageTitle: "Account Balance Report",
          breadcrumb: [
            {
              text: "Report",
              active: true
            },
            {
              text: "Account Balance",
              active: true
            }
          ]
        }
      },
      {
        path: "/report/account-group",
        name: "account-group-report",
        component: () => import("@/views/report/paymentaccount/PaymentAccountGroupReport.vue"),
        meta: {
          pageTitle: "Account Group Report",
          breadcrumb: [
            {
              text: "Report",
              active: true
            },
            {
              text: "Account Group",
              active: true
            }
          ]
        }
      },
      {
        path: "/report/agent-performance-summary/:agentCode?",
        props: true,
        name: "agent-performance-summary",
        component: () => import("@/views/report/agent/AgentPerformanceSummary.vue"),
        meta: {
          pageTitle: "Agent Performance Report",
          breadcrumb: [
            {
              text: "Report",
              active: true
            },
            {
              text: "Agent Performance Summary",
              active: true
            }
          ]
        }
      }
    ]
  }
];
