<template>
  <div>
    <vue-element-loading :active="true" is-full-screen spinner="bar-fade-scale" text="Session Expired. Please Login Again" />
  </div>
</template>

<script>
export default {
  name: "SessionTimeout"
};
</script>
