export default {
  SET_AGENT_LIST(state, agentList) {
    state.agentList = agentList;
  },
  SET_PAYMENT_ACCOUNT_LIST(state, paymentAccountList) {
    state.paymentAccountList = paymentAccountList;
  },
  SET_TRX_TYPE_LIST(state, trxTypeList) {
    state.trxTypeList = trxTypeList;
  }
};
