import { UPDATE_ADMIN_API_URL, UPDATE_AUTH_URL, UPDATE_LOTTERY_API_URL } from "@mutations";

export default {
  [UPDATE_AUTH_URL](state, val) {
    state.authUrl = val;
  },
  [UPDATE_ADMIN_API_URL](state, val) {
    state.adminApiUrl = val;
  },
  [UPDATE_LOTTERY_API_URL](state, val) {
    state.lotteryApiUrl = val;
  }
};
